import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { ChildrenProps } from "../../types";
import { initialState } from "./initialState";
import { authReducer } from "./reducers";
import { AuthActionType, AuthContextProps, AuthErrorType } from "./types";

const initialContext: AuthContextProps = {
  auth: initialState,
  setIsAuthenticated: () => null,
  setAuthError: () => null,
  setAuthToken: () => null,
  setOnramperId: () => null,
  setEmail: () => null,
  setTurnstileToken: () => null,
  setOtp: () => null,
};

const AuthContext = createContext<AuthContextProps>(initialContext);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthContextProvider");
  }
  return context;
};

export const AuthContextProvider: FC<ChildrenProps> = (
  props: ChildrenProps
) => {
  const [auth, dispatch] = useReducer(authReducer, initialState);

  const setIsAuthenticated = useCallback(
    (isAuthenticated: boolean) => {
      dispatch({
        type: AuthActionType.SetIsAuthenticated,
        payload: isAuthenticated,
      });
    },
    [dispatch]
  );

  const setAuthError = useCallback(
    (authError: AuthErrorType) => {
      dispatch({
        type: AuthActionType.SetAuthError,
        payload: authError,
      });
    },
    [dispatch]
  );

  const setAuthToken = useCallback(
    (authToken: string | null) => {
      dispatch({
        type: AuthActionType.SetAuthToken,
        payload: authToken,
      });
    },
    [dispatch]
  );

  const setOnramperId = useCallback(
    (onramperId: string | null) => {
      dispatch({
        type: AuthActionType.SetOnramperId,
        payload: onramperId,
      });
    },
    [dispatch]
  );

  const setOtp = useCallback(
    (otp: string) => {
      dispatch({
        type: AuthActionType.SetOtp,
        payload: otp,
      });
    },
    [dispatch]
  );

  const setEmail = useCallback(
    (email: string | null) => {
      dispatch({
        type: AuthActionType.SetEmail,
        payload: email,
      });
    },
    [dispatch]
  );

const setTurnstileToken = useCallback(
    (token: string | null) => {
        dispatch({
            type: AuthActionType.SetTurnstileToken,
            payload: token,
        });
    },
    [dispatch]
);

  const value = useMemo(
    () => ({
      auth,
      setIsAuthenticated,
      setAuthError,
      setAuthToken,
      setOnramperId,
      setEmail,
      setTurnstileToken,
      setOtp,
    }),
    [
      auth,
      setIsAuthenticated,
      setAuthToken,
      setOnramperId,
      setAuthError,
      setEmail,
      setTurnstileToken,
      setOtp,
    ]
  );

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};
