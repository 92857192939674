import { useParamContext, useTransactionContext } from '../providers';
import * as endpoints from "../config/endpoints";
import { useFetch } from "../utils/reactQuery";

export interface IntegrationResponse {
    message: Message;
}

export interface Message {
    integrations: Integration[];
}

export interface Integration {
    id:   string;
    name: string;
    icon: string;
}


const priority = ["coinbase", "robinhood", "paypal"];

const useIntegration = () => {
    const { transaction } = useTransactionContext();
    const { selectedCrypto } = transaction;
    const { params } = useParamContext();
    const { enableLink } = params;
  
    const { data, isLoading, refetch } = useFetch<IntegrationResponse>(
      `${endpoints.integrations}/mesh/${selectedCrypto?.id}`,
      {},
      {
        enabled: enableLink && !!selectedCrypto,
      }
    );

    const integrations = data?.message?.integrations ?? [];
    const sortedExchanges = integrations.sort((a, b) => {
        const priorityA = priority.indexOf(a.id.toLowerCase());
        const priorityB = priority.indexOf(b.id.toLowerCase());
    
        if (priorityA === -1 && priorityB === -1) {
            return a.name.localeCompare(b.name); 
        }
    
        if (priorityA === -1) return 1; 
        if (priorityB === -1) return -1; 
    
        return priorityA - priorityB; // Sort by priority order
    });
    const topIntegrations = sortedExchanges.length > 3 ? sortedExchanges.slice(0, 3) : integrations;
    const remainingIntegrations = sortedExchanges.length > 3 ? sortedExchanges.slice(3) : [];
    return {
      topIntegrations,
      remainingIntegrations,
      isLoading,
      total: integrations.length,
      refetch,
    };
}

export default useIntegration