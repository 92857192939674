import { Button, TextField } from "@onramper/oui";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useLanguage } from "../../../hooks/useLanguage";
import { useSignIn } from "../../../hooks/useSignIn";
import { ReactComponent as CheckMark } from "../../../icons/small-check-mark.svg";
import { useIndexedDbContext, useNavigationContext } from "../../../providers";
import { useAuthContext } from "../../../providers/AuthContextProvider";
import { SignInFrom } from "../../../types";
import AmountSummary from "../../common/AmountSummary/AmountSummary";
import ViewHeader from "../../common/ViewHeader/ViewHeader";
import ContainerView from "../ContainerView";
import Steps from "../Steps";
import styles from "./SignIn.module.css";
import { VerifyEmail } from "./VerifyEmail";
import { Turnstile } from "@marsidev/react-turnstile";
import { TURNSTILE_SITE_KEY } from "../../../constants";
import { useThemes } from "../../../hooks/useThemes";

export type SignInProps = {
  signInFrom: SignInFrom;
  extraData?: any;
};

export const SignIn: FC<SignInProps> = (props: SignInProps) => {
  const { signInFrom } = props;
  const { formatMessage } = useLanguage();

  const { getThemeClass } = useThemes();
  const { getLanguage } = useLanguage();

  const { setEmail, setTurnstileToken } = useAuthContext();
  const { onlyScreen, backScreen, nextScreen, backScreens } =
    useNavigationContext();

  const { setOnramperId } = useIndexedDbContext();
  const [rawEmail, setRawEmail] = useState<string | null>("");
  const {
    isLoading: isVerifyEmail,
    data,
    mutate: signIn,
    isError,
  } = useSignIn();
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isProcessingSignIn, setIsProcessingSignIn] = useState<boolean>(false);

  const [isSignInEnabled, setIsSignInEnabled] =
    useState<boolean>(!TURNSTILE_SITE_KEY);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ || email.length === 0;
    return emailRegex.test(email);
  };

  const emailInputHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const email = event.target.value;
      setRawEmail(email);
      if (validateEmail(email)) {
        setEmail(email);
        setEmailError(null);
      } else {
        setEmailError(formatMessage("signInView.body.emailInputError"));
      }
    },
    [],
  );

  const keyDownHandler = (event: { keyCode: number }) => {
    if (event.keyCode === 13 && !emailError && rawEmail) {
      signInHandler();
    }
  };

  const signInHandler = () => {
    signIn();
  };

  function handleTurnstileSuccess(token: string) {
    setTurnstileToken(token);
    setIsSignInEnabled(true);
  }

  useEffect(() => {
    const handleSignInResponse = async () => {
      setIsProcessingSignIn(true);
      if (isError) {
        setEmailError(formatMessage("signInView.body.signInError"));
      } else if (data?.status === "success") {
        await setOnramperId(data?.onramperId);
        nextScreen(
          <VerifyEmail signInFrom={signInFrom} extraData={props.extraData} />,
        );
      } else if (data?.status === "failed") {
        setEmailError(formatMessage("signInView.body.signInError"));
      }
      setIsProcessingSignIn(false);
    };
    handleSignInResponse();
  }, [data?.status, isError, nextScreen]);

  return (
    <div className={styles["view-root"]}>
      <ViewHeader
        backButtonAction={
          signInFrom === "session-timedout"
            ? () => onlyScreen(<ContainerView />)
            : signInFrom === "mesh"
              ? () => backScreens(2)
              : () => backScreen()
        }
        content={signInFrom === "checkout" ? <AmountSummary /> : null}
      />

      <div className={styles["view-body"]}>
        <span className={styles["view-body-title"]}>
          {formatMessage("signInView.body.title")}
        </span>
        <div className={styles["view-body-section"]}>
          <span className={styles["view-body-sub"]}>
            <CheckMark /> {formatMessage("signInView.body.sub1")}
          </span>
          <span className={styles["view-body-sub"]}>
            <CheckMark /> {formatMessage("signInView.body.sub2")}
          </span>
        </div>
        <TextField
          label={formatMessage("signInView.body.emailInput")}
          expanded
          disabled={!isSignInEnabled}
          value={rawEmail ?? ""}
          onChange={emailInputHandler}
          onKeyDown={keyDownHandler}
          error={!!emailError || data?.status === "failed"}
          helperText={emailError ?? ""}
        />
        {TURNSTILE_SITE_KEY && (
          <Turnstile
            siteKey={TURNSTILE_SITE_KEY}
            onSuccess={handleTurnstileSuccess}
            options={{
              theme: getThemeClass() === "dark-theme" ? "dark" : "light",
              language: getLanguage(),
            }}
          />
        )}
        <p className={styles["view-body-help-text"]}>
          {formatMessage("signInView.body.helperText1")}{" "}
          <a
            href="https://onramper.com/terms-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            <u>{formatMessage("signInView.body.helperText2")}</u>
          </a>{" "}
          {formatMessage("signInView.body.helperText3")}{" "}
          <a
            href="https://onramper.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <u>{formatMessage("signInView.body.helperText4")}</u>
          </a>
        </p>
      </div>
      <div className={styles["view-footer"]}>
        <Button
          label={formatMessage("signInView.button.signIn")}
          size="large-rectangle"
          expanded
          disabled={!rawEmail || emailError !== null}
          onClick={signInHandler}
          loading={isVerifyEmail || isProcessingSignIn}
        />
        {signInFrom === "checkout" && (
          <Button
            variant="secondary"
            label={formatMessage("signInView.button.continueWithoutSignIn")}
            size="large-rectangle"
            expanded
            onClick={() => {
              nextScreen(<Steps key={"Steps"} />);
            }}
          />
        )}
      </div>
    </div>
  );
};
