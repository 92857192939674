import { useState } from "react";
import { signin } from "../config/endpoints";
import { usePost } from "../utils/reactQuery";
import { useAuthContext } from "../providers/AuthContextProvider";
import { SignInResponse } from "../types";

export const useSignIn = () => {
  const url = signin;

  const { auth } = useAuthContext();
  const { email, turnstileToken } = auth;

  const { data, isLoading, error, isError, mutate } = usePost<
    SignInResponse,
    SignInResponse
  >(
    url,
    {},
    {
      email,
      turnstileToken,
    }
  );

  return {
    data,
    isLoading,
    error,
    isError,
    mutate,
  };
};
